<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link
	href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
	rel="stylesheet"
/>
<link
	rel="stylesheet"
	type="text/css"
	href="https://cdn.jsdelivr.net/npm/slick-carousel/slick/slick.css"
/>
<link
	rel="stylesheet"
	type="text/css"
	href="https://cdn.jsdelivr.net/npm/slick-carousel/slick/slick-theme.css"
/>

<link
	rel="stylesheet"
	href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
	integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
	crossorigin="anonymous"
	referrerpolicy="no-referrer"
/>

<div class="home-page">
	<!-- <div id="darkModeToggleBtn">
		<span><i class="fa-regular fa-moon"></i></span>
	</div> -->

	<header class="home-header">
		<div class="top-head">
			<div class="container">
				<div class="inner-wrapper">
					<div>
						<a href="mailto:info@fionpay.com"
							><i class="fa-regular fa-envelope"></i>
							{{ 'info@fionpay.com' }}</a
						>
					</div>
					<div>
						<a
							href="https://maps.app.goo.gl/VEacGHzSNFvsUGSm9"
							target="_blank"
							><i class="fa-solid fa-location-dot"></i> 1307,
							Horizon Tower, Ajman, UAE</a
						>
					</div>
				</div>
			</div>
		</div>
		<nav class="home-nav" [ngClass]="activeClass" id="nav">
			<div class="container">
				<div class="home-header">
					<ul class="nav-menu">
						<li class="menu-logo">
							<img
								class="logo-img"
								src="assets/img/logo.png"
								alt=""
							/><img
								class="dark-mode"
								src="assets/img/white-logo.png"
								alt=""
							/>
						</li>
					</ul>

					<div class="cMenu" [class.active]="isActive">
						<ul>
							<li class="nav-menu-item">
								<!-- <a
									routerLink="/documentation-v2"
									routerLinkActive="active"
									>Developers</a> -->
								<a href="#home">Home</a>
								<a href="#about">About Us</a>
								<a href="#features">Our Feature</a>
								<a href="#services">Services</a>
								<a href="#solution">Solutions</a>
							</li>
						</ul>
					</div>

					<div class="mobile-menu" (click)="toggleClass()">
						<i class="fa-solid fa-bars"></i>
					</div>
					<div class="login-btn">
						<a href="#become_a_partner">Contact Us</a>
					</div>
				</div>
			</div>
		</nav>
	</header>
	<section class="home-banner" id="home">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-6">
					<div class="banner-content">
						<h4>Fionpay: Your Digital Payment Gateway</h4>
						<h1>
							<span class="purple">Fast, Flexible, </span>
							<span class="blue">and Innovative</span> Payment
							Solutions for Your Business Needs
						</h1>
						<!-- <p>
							Ut enim ad minim veniam, quis nostrud exercitation
							ullamco laboris nisi ut aliquip ex ea commodo
							consequat. Duis aute irure dolor.
						</p>
						<div class="btn_blue">
							<a href="#become_a_partner">Learn More</a>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="abFio" id="about">
		<div class="container">
			<div class="row">
				<div class="col-md-6">
					<img
						src="assets/home/images/abFion1.png"
						class="abFioImg"
					/>
				</div>
				<div class="col-md-6 content-wrap">
					<div class="login-btn">
						<a href="#become_a_partner">About Fionpay</a>
					</div>
					<h2>
						Empowering Businesses with
						<span class="purple">Seamless</span>
						<span class="blue"> Payment </span> Solutions
					</h2>
					<p>
						At Fionpay, we are dedicated to revolutionizing online
						payments for businesses globally. As a licensed Payment
						Service Provider, we offer a diverse array of secure and
						reliable online payment methods. Our platform supports
						transactions across multiple currencies, ensuring a
						hassle-free payment experience for our clients and their
						customers.
					</p>
					<div class="btn_blue">
						<a href="#become_a_partner">Learn More</a>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="whyFinopay">
		<div class="container">
			<div class="head">
				<div class="headMain col-xxl-6 col-xl-8 col-md-10">
					<div class="login-btn">
						<a href="#become_a_partner">WHY CHOOSE US?</a>
					</div>
					<h2>
						Why Choose <span class="purple">Fion</span
						><span class="blue">pay?</span>
					</h2>
					<p>
						Lorem ipsum dolor sit amet consectetur. Eget quis
						sagittis quisque in tristique. Posuere lacinia tellus
						tortor faucibus et nulla donec phasellus elementum.
						Dolor molestie.
					</p>
				</div>
			</div>
			<div class="whyWrap">
				<div class="whyWrapA">
					<img src="assets/home/images/whyFino1.png" />
					<h4>Industry Expertise</h4>
					<p>
						With years of experience in perfecting online payments,
						Fionpay has empowered over 150 partners to increase
						their profits and expand their reach into 16+ countries.
					</p>
				</div>
				<div class="whyWrapB">
					<img src="assets/home/images/whyFino2.png" />
					<h4>Global Reach</h4>
					<p>
						Our platform facilitates transactions for over 2 million
						users worldwide, helping businesses seamlessly navigate
						and capitalize on international markets through one
						unified integration.
					</p>
				</div>
				<div class="whyWrapA">
					<img src="assets/home/images/whyFino3.png" />
					<h4>Innovation and Security</h4>
					<p>
						Committed to innovation, we continuously enhance our
						solutions to meet the evolving needs of businesses. Our
						robust security measures ensure that every transaction
						is safe and protected.
					</p>
				</div>
			</div>
		</div>
	</section>
	<section class="bestPayment">
		<div class="container">
			<div class="row">
				<div class="col-6">
					<!-- <img src="assets/home/images/payLeft.png" /> -->
				</div>
				<div class="col-6 content-wrapper">
					<h3>Looking for the Best Payment Service Provider?</h3>
					<p>You have definitely came to the right place.</p>
					<div class="btn_blue">
						<a href="#become_a_partner">Learn More</a>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="payForm" id="features">
		<div class="container">
			<div class="head">
				<div class="headMain col-xxl-6 col-xl-8 col-md-10">
					<div class="login-btn">
						<a href="#become_a_partner">Our Advantages</a>
					</div>

					<h2>
						User-Friendly<span class="blue"> Payment Form</span>
					</h2>
					<p>
						Our payment form is designed with simplicity in mind,
						requiring minimal information for a quick and easy
						payment process.
					</p>
				</div>
			</div>
			<div class="payFlex advantages-slider">
				<div class="payAdv">
					<img src="assets/home/images/adv1.png" />
					<h5>01</h5>
					<h4>24/7 Support & Service</h4>
					<p>
						We offer round-the-clock support to ensure that any
						issues are resolved promptly. Your satisfaction is our
						top priority.
					</p>
				</div>
				<div class="payAdv">
					<img src="assets/home/images/adv2.png" />
					<h5>02</h5>
					<h4>Effortless Integration</h4>
					<p>
						Integrating Fionpay into your system is as simple as a
						few clicks, making it hassle-free and efficient.
					</p>
				</div>
				<div class="payAdv">
					<img src="assets/home/images/adv3.png" />
					<h5>03</h5>
					<h4>Comprehensive Payment</h4>
					<p>
						We provide a full range of payment methods to cater to
						all your clients’ needs, across India and globally.
					</p>
				</div>
				<div class="payAdv">
					<img src="assets/home/images/adv2.png" />
					<h5>04</h5>
					<h4>Scalable Solutions</h4>
					<p>
						Whether you are a small business or a large enterprise,
						our solutions are designed to scale with your needs,
						without any limitations.
					</p>
				</div>
				<div class="payAdv">
					<img src="assets/home/images/adv3.png" />
					<h5>05</h5>
					<h4>Competitive Rates</h4>
					<p>
						We offer highly competitive rates, ensuring you get the
						best value for your money.
					</p>
				</div>
				<div class="payAdv">
					<img src="assets/home/images/adv2.png" />
					<h5>06</h5>
					<h4>Security & Peace of Mind</h4>
					<p>
						Our robust security measures ensure a risk-free
						experience for both businesses and customers.
					</p>
				</div>
			</div>
		</div>
	</section>
	<section class="support" id="services">
		<div class="container">
			<div class="head">
				<div class="headMain col-xxl-6 col-xl-8 col-md-10">
					<div class="login-btn">
						<a href="#become_a_partner">Payment Methods</a>
					</div>
					<h2>
						<span class="blue">Supported </span> Payment Methods
					</h2>
					<p>
						At Fionpay, we offer a wide array of payment methods to
						ensure your business can cater to clients from various
						regions with ease. Our robust and versatile platform
						integrates numerous local and international payment
						options, providing a seamless and comprehensive payment
						experience.
					</p>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-6 spLeft">
					<img src="/assets/home/images/supportL.png" class="spA" />
					<img src="assets/home/images/finoLogo.png" class="logoA" />
				</div>
				<div class="col-lg-6 spRight">
					<div class="spFlex">
						<div class="flag">
							<img src="assets/home/images/bangladesh.png" />
							<h4>Bangladesh</h4>
						</div>
						<div class="flag">
							<img src="assets/home/images/india.png" />
							<h4>India</h4>
						</div>
						<div class="flag">
							<img src="assets/home/images/global.png" />
							<h4>Global</h4>
						</div>
					</div>
					<div class="methods">
						<div class="methodA">
							<img src="assets/home/images/sp1.png" />
							<h4>
								E-Wallets:
								<span
									>Bkash, Nagad, Rocket, Upay, SureCash</span
								>
							</h4>
						</div>
						<div class="methodA">
							<img src="assets/home/images/sp2.png" />
							<h4>
								Bank Payment Methods:
								<span>Support for all major banks</span>
							</h4>
						</div>
						<div class="methodA">
							<img src="assets/home/images/sp3.png" />
							<h4>
								Transfer:
								<span>Easy and secure bank transfers</span>
							</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="integration" id="solution">
		<div class="container">
			<div class="head">
				<div class="headMain col-xxl-6 col-xl-8 col-md-10">
					<div class="login-btn">
						<a href="#become_a_partner">Integration Process</a>
					</div>
					<h2>
						Seamless <span class="blue">Integration </span> Process
					</h2>
					<p>
						At Fionpay, we are committed to providing payment
						solutions that align perfectly with your business needs
						and expectations. Our integration process is designed to
						be seamless, efficient, and fully supported by our
						expert technical team.
					</p>
				</div>
				<div class="row">
					<div class="col-lg-6">
						<div class="intFlex">
							<div class="accordion" id="accordionExample">
								<div class="accordion-item">
									<h2
										class="accordion-header"
										id="headingOne"
									>
										<span>1</span>
										<button
											class="accordion-button"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseOne"
											aria-expanded="true"
											aria-controls="collapseOne"
										>
											Comprehensive Payment Solutions
										</button>
									</h2>
									<div
										id="collapseOne"
										class="accordion-collapse collapse show"
										aria-labelledby="headingOne"
										data-bs-parent="#accordionExample"
									>
										<div class="accordion-body">
											We offer tailored payment solutions
											that are customized to meet your
											unique business requirements,
											ensuring a smooth and effective
											payment process.
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2
										class="accordion-header"
										id="headingTwo"
									>
										<span>2</span>
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseTwo"
											aria-expanded="false"
											aria-controls="collapseTwo"
										>
											Expert Technical Assistance
										</button>
									</h2>
									<div
										id="collapseTwo"
										class="accordion-collapse collapse"
										aria-labelledby="headingTwo"
										data-bs-parent="#accordionExample"
									>
										<div class="accordion-body">
											We offer tailored payment solutions
											that are customized to meet your
											unique business requirements,
											ensuring a smooth and effective
											payment process.
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2
										class="accordion-header"
										id="headingThree"
									>
										<span>3</span>
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseThree"
											aria-expanded="false"
											aria-controls="collapseThree"
										>
											Robust and Flexible REST API
										</button>
									</h2>
									<div
										id="collapseThree"
										class="accordion-collapse collapse"
										aria-labelledby="headingThree"
										data-bs-parent="#accordionExample"
									>
										<div class="accordion-body">
											We offer tailored payment solutions
											that are customized to meet your
											unique business requirements,
											ensuring a smooth and effective
											payment process.
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-6 intRightImg">
						<img src="assets/home/images/intRight.png" />
					</div>
				</div>
			</div>
		</div>
	</section>

	<footer>
		<div class="container">
			<div class="footerFlex">
				<div class="col-2">
					<div class="footer-logo">
						<img src="assets/home/images/black-logo.png" alt="" />
						<div class="social">
							<a href="">
								<i class="fa-brands fa-facebook"></i>
							</a>
							<a href="">
								<i class="fa-brands fa-instagram"></i>
							</a>
							<a href="">
								<i class="fa-brands fa-linkedin-in"></i>
							</a>
							<a href="">
								<i class="fa-brands fa-x-twitter"></i>
							</a>
						</div>
					</div>
				</div>
				<div class="">
					<h3>Quick Links</h3>
					<div class="footer-menu-item">
						<a routerLink="/documentation" routerLinkActive="active"
							>Developers</a
						>
						<a routerLink="/documentation" routerLinkActive="active"
							>Services</a
						>
						<a routerLink="/documentation" routerLinkActive="active"
							>Contact</a
						>
					</div>
				</div>
				<div class="">
					<h3>Information</h3>
					<div class="footer-menu-item">
						<a href="">Available payment methods</a>

						<a href="">Fees and conditions</a>

						<a href="">Custom-tailored solutions</a>
					</div>
				</div>
				<div class="">
					<h3>Connect Us</h3>
					<div class="footer-menu-item">
						<a href="mailto:info@fionpay.com"
							>Email: {{ 'info@fionpay.com' }}</a
						>
						<!-- <a href="">Support: supportfionpay.com</a>
						<a href="">Skype: live</a> -->
					</div>
				</div>
			</div>
		</div>
	</footer>

	<script
		type="text/javascript"
		src="https://cdn.jsdelivr.net/npm/slick-carousel/slick/slick.min.js"
	></script>
</div>
