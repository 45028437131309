import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environment/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  methodName = new Subject<any>();
  apiUrl:string = environment.backendHost;
  constructor(private http :HttpClient) {}
  sendMethod(data: any) {
    this.methodName.next({ data: data });
  }
  getMethod(){
    return this.methodName.asObservable();
  }
  getUsersRoles(data:any){
    return this.http.post(
      `${this.apiUrl}api/v1/rules/get_user_role_and_email`,data
    );
  }
  update(data:any){
    return this.http.put(
      `${this.apiUrl}api/v1/rules/update_permission`,data
    );
  }
  updateAdmin(data:any){
    return this.http.put(
      `${this.apiUrl}api/v1/employees/admin_permission`,data
    );
  }
  emailList(data:any){
   return this.http.post(
      `${this.apiUrl}api/v1/rules/emails_with_role`,data
    );
  }
}