import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { LocalStorageService } from '@services/admin/localstorage/local.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  apiUrl: string = environment.backendHost;
  objectRefresh = new Subject<any>();
  currentBalanceRefresh = new Subject<any>();
  pendingCountRefresh = new Subject<any>();
  isChangeThemeMode = new Subject<any>();
  // isAutoRefreshOBJ = new Subject<any>;
  constructor(private http: HttpClient,
		private localStorageService: LocalStorageService) { }

  getCurrentRole() {
    let userProfile: any = this.localStorageService.getUserProfile();
    return userProfile.userRoles[0];
  }
  getAllBanks(data:any) {
    return this.http.post(
      `${this.apiUrl}api/v1/mobile_bankings/custom_filter`,data
    );
  }
  /** BL Count Api Reftesh */
  countObjectRefresh(refresh: boolean) {
    this.objectRefresh.next({ refresh: refresh });
  }
  /** BL Count Api Reftesh */
  getcountObjectRefresh() {
    return this.objectRefresh.asObservable();
  }
  /** BL auto Refresh */
  isBLAutoRefresh(refresh: any) {
    this.objectRefresh.next({ refresh: refresh.refresh, pageNumber: refresh.pageNumber })
  }

  getIsBLAutoRefresh() {
    return this.objectRefresh.asObservable();
  }
  /** Current Balance Refresh */
  currentBalance(refresh: boolean) {
    this.currentBalanceRefresh.next({ refresh: refresh });
  }
  getCurrentBalance(){
    return this.currentBalanceRefresh.asObservable();
  }
  /** Pending Request Count Refresh */
  pedingCountRF(refresh: boolean) {
    this.pendingCountRefresh.next({ refresh: refresh });
  }
  getPedingCountRF(){
    return this.pendingCountRefresh.asObservable();
  }
  getPartnerDashboard(){
    return this.http.get(
      `${this.apiUrl}api/v1/partners/dashboard`
    );
  }
  getAgentsDashboard(){
    return this.http.get(
      `${this.apiUrl}api/v1/agent_webs/dashboard`
    );
  }
  getdistributorsDashboard(){
    return this.http.get(
      `${this.apiUrl}api/v1/distributors/dashboard`
    );
  }
  getDashboardData() {
    return this.http.get(
       `${this.apiUrl}api/v1/users/dashboard_data`
    );
  }

  getAllCompanies() {
    return this.http.get(
      `${this.apiUrl}api/v1/companies`
    );
  }

  getDistributors(company_id:string) {
    return this.http.get(
      `${this.apiUrl}api/v1/distributors?company_id=${company_id}`
    );
  }
  currentBalanceData(){
    return this.http.get(
      `${this.apiUrl}api/v1/users/current_balance`
    );
  }
  getPermissions(){
    return this.http.get(
      `${this.apiUrl}api/v1/users/current_permissions`
    );
  }
  paymentAllBanksOld(id:string) {
    return this.http.get(
      `${this.apiUrl}api/v1/users/check_payment_old?id=${id}`
    );
  }
  paymentAllBanks(id:string) {
    return this.http.get(
      `${this.apiUrl}api/v1/users/check_payment?id=${id}`
    );
  }
  
  agentModem(payload:any) {
    return this.http.post(
      `${this.apiUrl}api/v1/users/agent_modem`,
      payload
    );
  }
  bankLimit(ID:any){
    return this.http.get(
      `${this.apiUrl}api/v1/secure/payment_requests/get_bank_limit?id=${ID}`
    );
  }
  customerPaymentRequestOld(data:any){
    return this.http.post(
      `${this.apiUrl}api/v2/secure/payment_requests/customer_request`,
      data
    );
  }
  customerPaymentRequest(data:any){
    return this.http.post(
      `${this.apiUrl}api/v1/secure/payment_requests/customer_request`,
      data
    );
  }
  customerWithdrawRequest(data:any){
    return this.http.post(
      `${this.apiUrl}api/v1/secure/payment_requests/customer_withdraw_request`,
      data
    );
  }
  relieseModem(phone_number:string){
    return this.http.put(
      `${this.apiUrl}api/v1/secure/payment_requests/reliese_modem`,
      {phone_number: phone_number}
    );
  }
  getParentPermissionOBJ(parentName:string){
    let parentPermissionObject;
    let permissionsData:any = this.localStorageService.getAdminPermisson();
     if(permissionsData.length > 0){
       parentPermissionObject = permissionsData?.filter((item:any, index:any) => {
        return item.name == parentName;
       });
     }
       return parentPermissionObject
  }
  getPermissionOBJ(parentName:string){
      // let permissionsData:any = this.localStorageService.getAdminPermisson();
      // const parentPermissionObject = permissionsData?.filter((item:any, index:any) => {
      //   return item.name == parentName;
      //  });
     
      // let childPermission =  parentPermissionObject[0]?.permissions.filter((item:any, index:any) => {
      //   return item.name == childName;
      //  });
      //  return childPermission[0].permissions;
		let userProfile = this.localStorageService.getUserProfile();
     if(userProfile.userRoles[0] != "SuperAdmin"){
      let permissionsData:any = this.localStorageService.getAdminPermisson();
      const parentPermissionObject = permissionsData?.filter((item:any, index:any) => {
        return item.name == parentName;
       });
       console.log(parentPermissionObject)
       return parentPermissionObject
     }
      
     
  }
  resetData(){
    return this.http.put(
      `${this.apiUrl}api/v1/users/reset_payment_data`,
      {}
    );
  }
  getModemAndMerchant(){
    return this.http.get(
      `${this.apiUrl}api/v1/payment_transactions/payments_filter_data`
    );
  } 
  getMerchantCurrentPermission(){
    return this.http.get(
      `${this.apiUrl}api/v1/merchant/dashboard/current_permission`
      );
    }
    /******PR Counts */
    prAdminCount(){
      return this.http.get(
        `${this.apiUrl}api/v1/payment_requests/admin_pending_counts`
        );
      
    }
    prPartnerCount(){
      return this.http.get(
        `${this.apiUrl}api/v1/payment_requests/partner_pending_counts`
        );
      
    }
    prAgentCount(){
      return this.http.get(
        `${this.apiUrl}api/v1/payment_requests/agent_pending_counts`
        );
      
    }
    prDistributorCount(){
      return this.http.get(
        `${this.apiUrl}api/v1/payment_requests/distributor_pending_counts`
        );
      
    }
    agent(){
      return this.http.get(
        `${this.apiUrl}api/v1/users/get_agents`
        );
    }
  /** Theme Mode change */
  setThemeMode(item:any){
    this.isChangeThemeMode.next(item)
  }
  getThemeMode(){
    return this.isChangeThemeMode.asObservable();
  }
  getEmployeesDashboard(){
    return this.http.get(
      `${this.apiUrl}api/v1/employees/dashboard_details
      `
    );
  }
  prEmployeeCount(){
    return this.http.get(
      `${this.apiUrl}api/v1/employees/team_pending_counts
      `
      );
    
  }
}
