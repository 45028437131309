import { AfterViewInit, Component, ElementRef, HostListener } from '@angular/core';
import { LocalStorageService } from '@services/admin/localstorage/local.service';
import 'slick-carousel/slick/slick';

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit {
    activeClass: string = ""
    constructor(private el: ElementRef,private localStorageService: LocalStorageService) { }
    @HostListener('window:scroll', [])
    onScroll(): void {
        const offset = this.el.nativeElement.getBoundingClientRect().top;
        if (offset < 0) {
            this.activeClass = "active"
        } else {
            this.activeClass = ""
        }
    }

    isActive = false;

    toggleClass() {
      this.isActive = !this.isActive;
    }
    
    ngAfterViewInit(): void {
        $(this.el.nativeElement).find('.advantages-slider').slick({
            dots: true,
            arrow: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 560,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
        $(this.el.nativeElement).find('.slider-two').slick({
            dots: false,
            arrow: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        });
        $(this.el.nativeElement).find('.slider-three').slick({
            dots: false,
            arrow: true,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }


}

// Wait for the DOM to be ready
document?.addEventListener("DOMContentLoaded", () => {
    let that:any = this
    // Get the dark mode toggle button and body element
    const darkModeToggleBtn: HTMLButtonElement = document.getElementById("darkModeToggleBtn") as HTMLButtonElement;
    const body: HTMLElement = document.body;

    // Check if dark mode is enabled in local storage
    if (that?.localStorageService.getThemeMode() === "enabled") {
        body.classList.add("dark-mode");
    }

    // Toggle dark mode class and update local storage on button click
    darkModeToggleBtn?.addEventListener("click", () => {
        body.classList.toggle("dark-mode");
        // Check if dark mode is enabled and update local storage
        if (body.classList.contains("dark-mode")) {
            that.localStorageService.sendThemeMode("enabled")
        } else {
            that.localStorageService.sendThemeMode("disabled")
        }
    });
});